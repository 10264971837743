import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../redux/modifiDate'

import ContactPoints from '../../contactPoint/contactPoint'

const CurrentTenant = (props) => {
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            let tmp = null
            if(checkCurrentTenants(props) !== null){
                tmp = props.auctionId.relatedOrganizations.currentTenants.map((e) => {
                    return (
                        <div className={styles.infoBlock}>
                            <h3>Інформація про Чинного орендаря</h3>
                            <div className={styles.orgTitle}>
                                <p className={styles.desc}>Найменування Чинного орендаря</p>
                                <p>{contactName(e)}</p>
                            </div>
                            <div className={styles.orgContact1}>
                                <p className={styles.desc}>Контактні дані</p>
                                {contactPoint(e.contactPoint)}
                            </div>
                            <div className={styles.orgId}>
                                <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                                <p>{contactEDRPOU(e)}</p>
                            </div>
                            <div className={styles.orgAdress}>
                                <p className={styles.desc}>Юридична адреса</p>
                                <p>{contactAddress(e)}</p>
                            </div>
                            <div className={styles.orgAdress}>
                                <p className={styles.desc}>КОАТУУ</p>
                                <p>{contactKOATUU(e)}</p>
                            </div>
                            {currentContractTime(e)}
                        </div>
                    )
                })
            }
            return tmp
        }else if(
            props.auctionId.sellingMethod === "landArrested-priorityEnglish" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-ultra-fast" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-auction-prod" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-qualification-prod"
        ){
            let tmp
            tmp = props.auctionId.currentTenants.map((e) => {
                return (
                    <div className={styles.infoBlock}>
                        <h3>Інформація про Чинного орендаря</h3>
                        <div className={styles.orgTitle}>
                            <p className={styles.desc}>Найменування Чинного орендаря</p>
                            <p>{contactName(e)}</p>
                        </div>
                        {e.hasOwnProperty('contactPoint') === true && e.contactPoint === null
                            ? null
                            : <div className={styles.orgContact}>
                                <p className={styles.desc}>Контактні дані</p>
                                {contactPoint(e.contactPoint)}
                            </div>
                        }
                        {e.hasOwnProperty('representativeInfo') !== true
                            ? null
                            : <div className={styles.orgContact}>
                                <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                                <p>{e.representativeInfo}</p>
                            </div>
                        }
                        {e.hasOwnProperty('currentTenantValue') !== true
                            ? null
                            : <div className={styles.orgContact}>
                                <p className={styles.desc}>Орендна ставка (сума на рік)</p>
                                <p>{e.currentTenantValue.amount} грн.</p>
                            </div>
                        }
                        <div className={styles.orgId}>
                            <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                            <p>{contactEDRPOU(e)}</p>
                        </div>
                        <div className={styles.orgAdress}>
                            <p className={styles.desc}>Юридична адреса</p>
                            <p>{contactAddress(e)}</p>
                        </div>
                        <div className={styles.orgAdress}>
                            <p className={styles.desc}>КОАТУУ</p>
                            <p>{contactKOATUU(e)}</p>
                        </div>
                        {currentContractTime(e)}
                    </div>
                )
            })
            return tmp
        }
    }
    return null
}

function checkCurrentTenants(props){
    if(props.auctionId === null) return null
    if(props.auctionId.hasOwnProperty('relatedOrganizations') !== true) return null
    if(props.auctionId.relatedOrganizations === null) return null
    if(props.auctionId.relatedOrganizations.hasOwnProperty('currentTenants') !== true) return null
    if(props.auctionId.relatedOrganizations.currentTenants === null) return null
    if(props.auctionId.relatedOrganizations.currentTenants.length === 0) return null
    return true
}

function contactKOATUU(props){
    if(props.hasOwnProperty('address') !== true) return null
    if(props.address === null) return null
    if(props.address.hasOwnProperty('addressID') !== true) return null
    if(props.address.addressID === null) return null
    if(props.address.addressID.hasOwnProperty('id') !== true) return null
    if(props.address.addressID.id === null) return null
    return props.address.addressID.id
}

function contactName(props){
    if(props.hasOwnProperty('name') !== true) return null
    if(props.name === null) return null
    return props.name.uk_UA
}

function currentContractTime(props){
    let tmp = ''
    if(props.hasOwnProperty('currentContractTime') !== true) return null
    if(props.currentContractTime === null) return null
    if(
        props.currentContractTime.dateFrom !== null &&
        props.currentContractTime.dateTill !== null
    ){
        return (
            <>
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Дата укладення та закінчення договору оренди</p>
                    <p></p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Дата та час початку</p>
                    <p>{modifiDateString(props.currentContractTime.dateFrom, 3)}</p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Дата та час закінчення</p>
                    <p>{modifiDateString(props.currentContractTime.dateTill, 3)}</p>
                </div>
            </>
        )
    }
    return tmp
}

function contactPoint(e){
    if(e !== null){
        return <ContactPoints data={e} />
    }
    return null
}

function contactEDRPOU(props){
    if(props.hasOwnProperty('identifier') !== true) return null
    if(props.identifier === null) return null
    if(props.identifier.id === null) return null
    return props.identifier.id
}

function contactAddress(props){
    let tmp = ''
    if(props.hasOwnProperty('address') !== true) return null
    if(props.address === null) return null
    if(props.address.hasOwnProperty('postalCode')){
        if(props.address.postalCode !== null){
            tmp += props.address.postalCode + ', '
        }
    }
    if(props.address.hasOwnProperty('countryName')){
        if(props.address.countryName !== null){
            tmp += props.address.countryName.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('region')){
        if(props.address.region !== null){
            tmp += props.address.region.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('locality')){
        if(props.address.locality !== null){
            tmp += props.address.locality.uk_UA + ', '
        }
    }
    if(props.address.hasOwnProperty('streetAddress')){
        if(props.address.streetAddress !== null){
            tmp += props.address.streetAddress.uk_UA
        }
    }                                    
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(CurrentTenant)
