import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import {viewStringValue} from '../../api/valueToString'

const ProcedureStartValue = (props) => {
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.value !== null){
            tmp = viewStringValue(props.auctionId.value)
        }
    }
    return (
        <div className={styles.lotStartPriceBlock}>
            <p>Початкова ціна продажу лоту</p>
            <p className={styles.lotStartPrice}>{tmp}</p>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProcedureStartValue)