const thumbnailApi = {
    getDocumentThumbnailLink: function getDocumentThumbnailLink(document, width, height) {
        let url = `${process.env.REACT_APP_ENDPOINT_PROZORO_THUMBNAILS}/${document?._ds_scope ?? 'public'}/${document._ds_id}`
        if (width) url += `&w=${width}`
        if (height) url += `&h=${height}`
        // console.log(url)
        return url
    }
}

export default thumbnailApi
