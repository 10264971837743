import React from 'react';
import styles from './procedurePeriodsBlock.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from "../../../../../../redux/modifiDate";
import {paymentPopup, paymentPopup1, paymentPopupGetData} from "../../../../../../redux/paymentPopup";
import {setUniversalError} from "../../../../../../redux/actions";
import {
    getAuctionsAwardPeriodName,
    getAuctionsPeriodName,
} from "../../../../../../api/dictonaries/statusAuctions";

const ProcedurePeriodsBlock = (props) => {
    return (
        <div className={styles.auctionSteps}>
            <h2>Періоди процедури</h2>

            {viewPeriod(props.auction, 'start')}
            {viewPeriod(props.auction, 'rectificationPeriod')}
            {viewPeriod(props.auction, 'questionPeriod')}
            {viewPeriod(props.auction, 'transferPriorityPeriod')}
            {viewPeriod(props.auction, 'tenderPeriod')}
            {viewPeriod(props.auction, 'auctionPeriod')}
            {viewPeriod(props.auction, 'admissionPeriod')}
            {viewPeriod(props.auction, 'verificationPeriod')}
            {viewPeriod(props.auction, 'qualificationPeriod')}

            {/*award periods:*/}
            {viewPeriod(props.auction, 'signingPeriod', 'award')}
            {viewPeriod(props.auction, 'admissionPeriod', 'award')}

            {/*contract periods:*/}
            {/*{viewPeriod(props.auction, 'some period', 'constact')}*/}

        </div>)
}

function viewPeriod(auction, periodName, type = 'auction') {
    let period = null,
        periodNameText = null,
        hasPeriod = false

    // eslint-disable-next-line default-case
    switch (type) {
        case 'auction':
            period = auction.hasOwnProperty(periodName) ? auction[periodName] : null
            periodNameText = getAuctionsPeriodName(auction.sellingMethod, periodName)
            hasPeriod = auction?.hasOwnProperty(periodName)
            break
        case 'award':
            period = auction?.awards[0]?.hasOwnProperty(periodName) ? auction.awards[0][periodName] : null
            hasPeriod = auction?.awards[0]?.hasOwnProperty(periodName)
            periodNameText = getAuctionsAwardPeriodName(auction.sellingMethod, periodName)
            break
    }
    if (periodName === 'start' && auction?.hasOwnProperty('datePublished')) {
        period = {
            'startDate': auction.datePublished
        }
    }
    if (hasPeriod !== true && type !== 'award') {
        return null
    }

    let periodIcon = null,
        now = Date.now(),
        startDate = period?.startDate ? new Date(period?.startDate) : null,
        endDate = period?.endDate ? new Date(period?.endDate) : null,
        styleClass = styles.inactive

    startDate = startDate?.getTime()
    endDate = endDate?.getTime()

    switch (true) {
        case(startDate > now || (!startDate && !endDate)):
            periodIcon = viewOnOffIconDate(1)
            break
        case(startDate <= now && endDate >= now):
            periodIcon = viewOnOffIconDate(2)
            styleClass = null
            break
        default:
            periodIcon = viewOnOffIconDate(3)
            styleClass = null
    }

    return (
        <div className={styles.stepBlock}>
            <div className={styles.stepIcon}>
                {periodIcon}
            </div>
            <div className={styles.stepInfo}>
                <h3 className={styleClass}>{periodNameText}</h3>
                {startDate ?
                    <p> {periodName !== 'start' ? 'з' : null} {modifiDateString(period.startDate, 3, false)}</p>
                    : null}
                {endDate ?
                    <p>по {modifiDateString(period.endDate, 3, false)}</p>
                    : null}
            </div>
        </div>
    )
}

function viewOnOffIconDate(type) {
    switch (type) {
        case 1:
            return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g>
                    <path fill="#e2e2ea"
                          d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                    <path fill="#fff" fillRule="evenodd"
                          d="M12.68,14V12.73a3.37,3.37,0,1,1,6.73,0V14h.95a1,1,0,0,1,1,1v6.75a1,1,0,0,1-1,1H11.64a1,1,0,0,1-1-1V14.93a1,1,0,0,1,1-1Zm-.8,1.21v6.29h8.24V15.16Zm6.32-1.23v-1.2a2.15,2.15,0,1,0-4.3,0v1.2Z"/>
                </g>
            </svg>
        case 2:
            return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g>
                    <path fill="#00aff0"
                          d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                    <path fill="#fff" fillRule="evenodd"
                          d="M9.33,16A6.67,6.67,0,1,0,16,9.33,6.67,6.67,0,0,0,9.33,16Zm4.56,3.19a.7.7,0,0,0,1,.21l4.35-2.81a.7.7,0,0,0,0-1.18L14.86,12.6a.76.76,0,0,0-.38-.11.7.7,0,0,0-.7.7v5.62A.7.7,0,0,0,13.89,19.19Z"/>
                </g>
            </svg>
        case 3:
            return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="-9 5 28 6">
                <g>
                    <path className="svgIcon" fill="#00b191" fillRule="evenodd"
                          d="M1.14,2.65a.68.68,0,0,0-.95,0,.68.68,0,0,0,0,1L4.08,7.44a.67.67,0,0,0,1,0L10.5,1.1a.66.66,0,1,0-1-.87L4.51,6Z"/>
                </g>
            </svg>
        default:
            return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g>
                    <path fill="#e2e2ea"
                          d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                    <path fill="#fff" fillRule="evenodd"
                          d="M12.68,14V12.73a3.37,3.37,0,1,1,6.73,0V14h.95a1,1,0,0,1,1,1v6.75a1,1,0,0,1-1,1H11.64a1,1,0,0,1-1-1V14.93a1,1,0,0,1,1-1Zm-.8,1.21v6.29h8.24V15.16Zm6.32-1.23v-1.2a2.15,2.15,0,1,0-4.3,0v1.2Z"/>
                </g>
            </svg>
    }
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull, token: state.start.token, userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    paymentPopup, paymentPopup1, paymentPopupGetData, setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcedurePeriodsBlock)