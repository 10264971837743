export function textMonth(month) {
    switch (month) {
        case 0:
            return 'січня'
        case 1:
            return 'лютого'
        case 2:
            return 'березня'
        case 3:
            return 'квітня'
        case 4:
            return 'травня'
        case 5:
            return 'червня'
        case 6:
            return 'липня'
        case 7:
            return 'серпня'
        case 8:
            return 'вересня'
        case 9:
            return 'жовтня'
        case 10:
            return 'листопада'
        case 11:
            return 'грудня'
        default:
            return month
    }
}

export function numberToString(priceAsNumber) {

    const numbers = ["", "одна", "два", "три", "чотири", "п'ять", "шість", "сім", "вісім", "дев'ять",
        "", "одинадцять", "дванадцять", "тринадцять", "чотирнадцять", "п'ятнадцять", "шістнадцять", "сімнадцять", "вісімнадцять", "дев'ятнадцять",
        "", "десять", "двадцять", "тридцять", "сорок", "п'ятдесят", "шістдесят", "сімдесят", "вісімдесят", "дев'яносто",
        "", "сто", "двісті", "триста", "чотириста", "п'ятсот", "шістсот", "сімсот", "вісімсот", "дев'ятсот",
        "тисяч", "тисяча", "тисячі", "тисячі", "тисячі", "тисяч", "тисяч", "тисяч", "тисяч", "тисяч",
        "міліонів", "міліон", "міліона", "міліона", "міліона", "міліонів", "міліонів", "міліонів", "міліонів", "міліонів",
        "мільярдів", "мільярд", "мільярда", "мільярда", "мільярда", "мільярдів", "мільярдів", "мільярдів", "мільярдів", "мільярдів"]

    const uahStrings = ["гривень", "гривня", "гривні", "гривні", "гривні", "гривень", "гривень", "гривень", "гривень", "гривень"]
    const kopStrings = ["копійок", "копійка", "копійки", "копійки", "копійки", "копійок", "копійок", "копійок", "копійок", "копійок"]

    function num2str(money) {
        let hrn = "", kop = ""
        money = money.replace(",", ".")
        money = Math.round(money * 100) / 100 + ""
        if (money.indexOf(".") != -1) {
            hrn = money.substr(0, money.indexOf("."))
            kop = money.substr(money.indexOf(".") + 1)
        } else hrn = money
        if (kop.length === 1) kop += "0"
        let uahString = hrn > 0 ? propis(hrn, uahStrings) : ''
        let kopString = propis(kop, kopStrings)
        let res

        res = kopString != "" ? uahString + " " + kopString : uahString;
        if (uahString === "") res = kopString;
        if (kopString.length === 0) res += " нуль " + kopStrings[0]
        // console.log(res)
        return res
    }

    function propis(price, D) {
        let litera = " ",
            sotny = " ",
            desatky = " ",
            edinicy = " ",
            k = 0
        let M = new Array(10);
        for (let j = 0; j < 10; ++j) {
            M[j] = new Array(numbers.length);
        }
        for (let i = 0; i < numbers.length; i++) {
            for (let j = 0; j < 10; j++) {
                M[j][i] = numbers[k++]
            }
        }
        for (let i = 0; i < price.length; i += 3) {
            // console.log(M)
            sotny = desatky = edinicy = ""
            if (getNumber(i + 2, 2, price) > 10 && getNumber(i + 2, 2, price) < 20) {
                edinicy = " " + M[getNumber(i + 1, 1, price)][1] + " " + M[0][i / 3 + 3]
                if (i === 0) edinicy += D[0]
            } else {
                edinicy = M[getNumber(i + 1, 1, price)][0]
                if (edinicy === "один" && (i === 3 || D === kopStrings)) edinicy = "одна"
                if (edinicy === "одна" && (i === 6 )) edinicy = "один"
                if (edinicy === "два" && (getNumber(i + 1, 1, price) > 1 || i === 3 || D === kopStrings)) edinicy = "дві"

                if (!(i === 0 && edinicy != "")) edinicy += " " + M[getNumber(i + 1, 1, price)][i / 3 + 3]
                if (edinicy === " ") {
                    edinicy = ""
                } else {
                    if(edinicy != " " + M[getNumber(i + 1, 1, price)][i / 3 + 3]) edinicy = " " + edinicy
                }
                edinicy += i === 0 ? " " + D[getNumber(i + 1, 1, price)] : ''
                if ((desatky = M[getNumber(i + 2, 1, price)][2]) !== "") desatky = " " + desatky
            }
            // console.log(getNumber(i + 3, 1, price))
            if ((sotny = M[getNumber(i + 3, 1, price)][3]) != "") sotny = " " + sotny
            if (price.substr(price.length - i - 3, 3) === "000" && edinicy === " " + M[0][i / 3 + 3]) edinicy = ""
            litera = sotny + desatky + edinicy + litera
        }
        if (litera === " " + uahStrings[0]) return "нуль" + litera
        else return litera.substr(1)
    }

    function getNumber(start, len, string) {
        if (start > string.length) return 0
        else return Number(string.substr(string.length - start, len))
    }

    return num2str(priceAsNumber + "")
}
